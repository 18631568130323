import { CONFIG } from '@global/constants/urls';
import { get } from './API';

export const getPreview = async (previewId: string, onError: (error: any) => any) => {
  try {
    const preview = await get(`${CONFIG.STRAPI_API_URL}/api/previews/${previewId}`, {
      'Content-Type': 'application/json',
    });
    return preview;
  } catch (error: any) {
    onError(error);
    return null;
  }
};
